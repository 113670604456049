import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';


import { styles } from '@storybook/design-system';
const { breakpoint, color, typography } = styles;

const IconLearnStorybook = props => (
  <svg width="80" height="80" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M26.5 62.5a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-4.8a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4z"
        fill="#37D5D3"
      />
      <path d="M60.25 19.5v16h-16c0-8.837 7.163-16 16-16z" fill="#FFAE00" />
      <path
        d="M54.524 46.34l7.663 13.028a1 1 0 0 1-.862 1.507H45.998a1 1 0 0 1-.862-1.507L52.8 46.34a1 1 0 0 1 1.724 0z"
        fill="#1EA7FD"
      />
      <path
        d="M22.5 19.5h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4h-8a4 4 0 0 1-4-4v-8a4 4 0 0 1 4-4z"
        fill="#FF4785"
      />
      <path
        d="M10 0a1.5 1.5 0 0 1 1.5 1.5v77a1.5 1.5 0 0 1-3 0v-77A1.5 1.5 0 0 1 10 0zm60 0a1.5 1.5 0 0 1 1.5 1.5v77a1.5 1.5 0 0 1-3 0v-77A1.5 1.5 0 0 1 70 0z"
        fillOpacity=".1"
        fill="#F40"
      />
      <path
        d="M0 10a1.5 1.5 0 0 1 1.5-1.5h77a1.5 1.5 0 0 1 0 3h-77A1.5 1.5 0 0 1 0 10zm0 60a1.5 1.5 0 0 1 1.5-1.5h77a1.5 1.5 0 0 1 0 3h-77A1.5 1.5 0 0 1 0 70z"
        fillOpacity=".1"
        fill="#F40"
      />
    </g>
  </svg>
);


const Img = styled.img`
  display: inline-block;
  vertical-align: middle;
  text-align:center;
  max-width: 50vw;
  max-height: 50vh;

  &&:hover {
    filter: saturate(5) hue-rotate(175deg);
  }

  .learnstorybook-text {
    fill: ${color.darker};
  }

  ${props =>
    props.isInverted &&
    `
    .learnstorybook-box {
      fill: ${color.lightest};
    }

    .learnstorybook-s {
      fill: transparent;
    }
  `}
`;



export const ZImg = props => (
   <Img  {...props}/>
);

ZImg.propTypes = {
  image: PropTypes.text
};

ZImg.defaultProps = {
  src: "/software_develop_23.jpg",
};




export default IconLearnStorybook;
